cs.ns("app.ui.composite.directEntry");
app.ui.composite.directEntry.ctrl = cs.clazz({
    extend:   app.ui.composite.abstract.modal.ctrl,
    dynamics: {},
    protos:   {
        create () {
            this.base(app.ui.composite.directEntry.model, app.ui.composite.directEntry.view);
        },

        acceptModal () {
            let token = this.model.value('data:token', '')
            this.model.value('data:errorMessage', '');

            if (token) {
                this.publishEventToParent("readUrlMap", token, (result) => {
                    if (result.chn || result.cnt) {
                        this.acceptCallback(token)
                    } else {
                        this.model.value('data:errorMessage', i18next.t(`appDirectEntry.alert.unknownToken`));
                    }
                }, () => {
                    this.model.value('data:errorMessage', i18next.t(`appDirectEntry.alert.unknown`));
                });
            } else {
                this.model.value('data:errorMessage', i18next.t("appDirectEntry.alert.noInput"));
                this.model.value("command:focus", true);
            }
        }

    }
});